.mygrid {
    display: grid;
    height: 100%;
    grid-template-columns: 65% 35%;
}

.my-button {
    display: flex;
    justify-content: center;
}

.input {
    padding-top: 10px;
}

.two-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: 15%;
    margin-right: 15%;
}
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.to-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.search-button {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 10%;
}
.actual-button {
    width: 100%;
}
.text-field-actual {
    width: 100%;
}
.some-space {
    margin: 20px;
}
.to-center-list {
    display: flex;
    justify-content: center;
    align-items: center;
}
.add-border {
    border: 3px solid black;
    height: 100%;
    padding-top: 30px;
    padding-bottom: 40px;
}
.margin-bot {
    margin: 5%;
    margin-bottom: 30px;
}
.input-container{
    border: 3px solid black;
    margin-top: 5%;
    padding: 3%;
    margin:5%;
}